<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/hospital_information_bg.png" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title mb20">信息化产品</p>
            <p class="font-txt">采购平台 <span class="font-txt-tag">|</span> SPD</p>
            <!-- <div class="next-btn">进一步了解</div> -->
          </div>
          <!-- <div class="bg-text-right">
          <p class="font-one">Technology leads transform, Data drives serviceValue</p>
          <p class="font-two">Transparent Word</p>
        </div> -->
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <div class="h100"></div>
        <Tab :tabs="tabs" @tabChange="onTabChange" :currentIndex="currentPageIndex" ref="tabIndex"> </Tab>
        <Title titleZh="产品介绍" titleEn=""></Title>
        <!-- 医链云SAAS-医院供应链平台 介绍-->
        <div class="contain-intro-box" v-show="currentPageIndex == 0">
          <img src="../../assets/images/hospital_information_bg1.png" alt="" />
          <div class="contain-intro-txt">
            <div class="contain-intro-title">医链云-供应链采购平台</div>
            <p>传世医链云是医院面向供应商的互联网采购平台。</p>
            <p>
              传世医链云通过互联网技术方便医院线上管理供应商的电子化资质材料、方便医院线上下达采购需求、方便医院线上处理供应商送货入库、方便医院线上结算。
            </p>
            <p>
              传世医链云提供供应商移动端工具-恋链，方便供应商实时受理医院采购通知、方便线上提供配送单据与发票、方便线上对账医院结算。
            </p>
            <p>传世医链云率先提供一级库在线管理功能、扫码入库功能、UDI码追溯功能，实现轻量化SPD管理能力。</p>
          </div>
        </div>
        <!-- 医链云-区域供应链采购平台 介绍-->
        <div class="contain-intro-box" v-show="currentPageIndex == 1">
          <img src="../../assets/images/hospital_information_bg2.png" alt="" />
          <div class="contain-intro-txt">
            <div class="contain-intro-title">医链云-区域供应链采购平台</div>
            <p>区域医链云是为医院和医药供应商搭建的一个医药运营供应链平台。</p>
            <p>
              满足总院对分院、等级医院对社区医院、集团对下属医院的采购、结算管控；实现医疗机构采购订单网上传递给供应商，配送信息及时传递到医疗机构，提高采购效率。
            </p>
            <p>
              通过对供应商资质证照全面电子化管理，对医疗机构的医药供应链数据智能分析，帮助医院集团和政府实现对医院医药供应链全环节的监管。
            </p>
          </div>
        </div>
        <!-- SPD-耗材管理系统 介绍-->
        <div class="contain-intro-box" v-show="currentPageIndex == 2">
          <img src="../../assets/images/hospital_information_bg3.png" alt="" />
          <div class="contain-intro-txt">
            <div class="contain-intro-title">SPD-耗材管理系统</div>
            <p>传世耗材SPD覆盖低值普通医用耗材、高值医用耗材的院内UDI码全流程物流管理。</p>
            <p>
              传世耗材SPD帮助医院控制“跑冒滴漏”乱象；帮助医院规范执行《医疗机构医用耗材管理办法》政策；帮助医院降低耗占比，改善财务成本，配合好国家医保体系改革大局。
            </p>
            <p>
              传世耗材SPD可以根据医院物资的定数消耗和扫码计费，对配送计划和动线智能计算，动态调节科室申请与库房补货频次，合理规划配送路径和时间，降低管理成本。并能与院内HIS、HRP、智能耗材柜系统、智能冰箱系统等系统无缝对接，提供基于UDI码的信息追溯和可视化监控。
            </p>
          </div>
        </div>
        <!-- SPD-药品管理系统 介绍-->
        <div class="contain-intro-box" v-show="currentPageIndex == 3">
          <img src="../../assets/images/hospital_information_bg4.png" alt="" />
          <div class="contain-intro-txt">
            <div class="contain-intro-title">SPD-药品管理系统</div>

            <p>
              传世药品SPD以院内药品管理为主，覆盖药库、门诊药房、住院药房，甚至可以深至药柜、摆药机、急救车的细粒度管理。
            </p>
            <p>
              传世药品SPD帮助医院实现“零库存”用后结算；帮助医院完善互联网医院建设的供应保障；帮助医院提升院务管理水平。
            </p>
            <p>
              传世药品SPD通过供应商院内虚拟库存和医院使用后结算的方式，实现医院的零库存。同时通过批次级的精细化库存管理和追溯，保障患者用药安全。传世药品SPD与院内HIS、HRP、自动发药机系统、包药机系统等系统无缝对接，提供全程信息追溯和可视化监控。
            </p>
          </div>
        </div>
        <div class="h63"></div>
      </div>
      <!-- 灰色内容区域 -->
      <div class="grey-box">
        <div class="grey-title">特色优势</div>
        <GreyBox v-show="currentPageIndex == 0" :boxList="boxList1"></GreyBox>
        <GreyBox v-show="currentPageIndex == 1" :boxList="boxList2"></GreyBox>
        <GreyBox v-show="currentPageIndex == 2" :boxList="boxList3"></GreyBox>
        <GreyBox v-show="currentPageIndex == 3" :boxList="boxList4"></GreyBox>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>

<script>
import Tab from '@/components/tab/tab.vue'; //选项卡组件
import GreyBox from '@/components/box/greyBox.vue'; //盒子组件
import Title from '@/components/title/title.vue'; //标题组件
import mobilePage from '@/views/hospital/mobile/hospital_information.vue'; //移动端页面
export default {
  name: 'home',
  components: {
    GreyBox,
    Title,
    Tab,
    mobilePage,
  },
  data() {
    return {
      currentPageIndex: 0,
      tabs: [
        { title: '医链云SAAS-医院供应链平台' },
        { title: '医链云-区域供应链平台' },
        { title: 'SPD-耗材管理系统' },
        { title: 'SPD-药品管理系统' },
      ],
      boxList1: [
        {
          url: require('../../assets/images/hospital_information_a1.png'),
          title: '医院零库存管理',
          txt: '',
        },
        {
          url: require('../../assets/images/hospital_information_a2.png'),
          title: '支持药品、普耗、高植耗材、试剂、中药材、后勤物资的六大类品种',
          txt: '<p>医院采购信息平台化，医药物资采集配送一体化，随采随发，结合药链云强大的配送体系及时将医院物资配送医院，可帮助医院实现零库存管理；</p>',
        },
        {
          url: require('../../assets/images/hospital_information_a3.png'),
          title: '线上询价-采购-供应-入库-结算等一体化流程',
          txt: '<p>医院采购信息平台化，医药物资采集配送一体化，随采随发，结合药链云强大的配送体系及时将医院物资配送医院，可帮助医院实现零库存管理；</p>',
        },
        {
          url: require('../../assets/images/hospital_information_a4.png'),
          title: '与医院HIS/SPD、供应商ERP无缝对接',
          txt: '<p>医院采购信息平台化，医药物资采集配送一体化，随采随发，结合药链云强大的配送体系及时将医院物资配送医院，可帮助医院实现零库存管理；</p>',
        },
        {
          url: require('../../assets/images/hospital_information_a5.png'),
          title: '供应商资质证照管理',
          txt: '<p>支持供应商资质证照管理，为医院提供资质合规性审核、效期监测等服务，使资质管理更合规、更规范、更便捷。</p>',
        },
      ],
      boxList2: [
        {
          url: require('../../assets/images/hospital_information_b1.png'),
          txt: '<p>支持药品、普耗、高植耗材、试剂、中药材、后勤物资的六大类品种平台采购和配送。</p>',
        },
        {
          url: require('../../assets/images/hospital_information_b2.png'),
          txt: '<p>支持总院+分院、紧密型医联体、医共体的采购管控模式。</p>',
        },
        {
          url: require('../../assets/images/hospital_information_b3.png'),
          txt: '<p>支持与政府监管平台、医院HIS/SPD、供应商ERP无缝对接</p>',
        },
        {
          url: require('../../assets/images/hospital_information_a5.png'),
          txt: '<p>支持供应商资质证照管理，为医院提供资质合规性审核、效期监测等服务</p>',
        },
        {
          url: require('../../assets/images/hospital_information_b5.png'),
          txt: '<p>支持医药供应链全流程运营数据BI分析</p>',
        },
      ],
      boxList3: [
        {
          url: require('../../assets/images/hospital_information_c1.png'),
          txt: '<p>支持试剂、普通耗材、高值耗材、后勤物资</p>',
        },
        {
          url: require('../../assets/images/hospital_information_c2.png'),
          txt: '<p>定数与非定数结合 ，通过定数应用，动态调节科室申请与库房补货频次，减少医护人员工作量</p>',
        },
        {
          url: require('../../assets/images/hospital_information_c3.png'),
          txt: '<p>配送动线智能计算，通过路径规划，智能计算配送路线与配送班次</p>',
        },
        {
          url: require('../../assets/images/hospital_information_c4.png'),
          txt: '<p>配货计划动态计算，通过对科室库存的消耗情况自动计算科室补货计算，确保库存不断供、不浪费</p>',
        },
        {
          url: require('../../assets/images/hospital_information_c5.png'),
          txt: '<p>支持多种设备对接，无缝对接耗材柜、自动发药机、气动物流等</p>',
        },
        {
          url: require('../../assets/images/hospital_information_c6.png'),
          txt: '<p>提供全面可视化监控，让库房人员直观监控库存及配送管理情况</p>',
        },
      ],
      boxList4: [
        {
          url: require('../../assets/images/hospital_information_d1.png'),
          title: '结算点可自由配置',
          txt: '<p>可根据业务情况和药品类型对药品结算点进行一级库入库、二级库入库或二级库消耗等多种结算点配置。</p>',
        },

        {
          url: require('../../assets/images/hospital_information_d2.png'),
          title: '全链条库存可视化',
          txt: '<p>药房库存药库可视、补货单可自动生成，自动审批（可配置）后由药库主动进行补货作业；</p><p>药库库存供应商可视，实现月度智能补货大计划和药库补货订单自动生成，供应商主动配送。</p>',
        },
        {
          url: require('../../assets/images/hospital_information_d3.png'),
          title: '质量管理更加精细化',
          txt: '<p>供应商、药品资质证照及授权链管理实现电子化，到期及近效期自动预警；对库存的养护按照GSP标准进行严格的管理，确保在库药品安全。</p>',
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.index) {
      this.currentPageIndex = Number(this.$route.query.index);
      this.$refs.tabIndex.currentIndex = this.currentPageIndex;
    }
  },
  methods: {
    onTabChange(item, index) {
      this.currentPageIndex = index;
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    min-height: 450px;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 45px;
        }
        .font-txt {
          font-size: 24px;
          .font-txt-tag {
            margin: 0 33px;
          }
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
    .contain-intro-box {
      display: flex;
      img {
        width: 68%;
      }
      .contain-intro-txt {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        flex: 1;
        text-align: left;
        padding: 40px;
        background: #fafafa;
        .contain-intro-title {
          font-size: 20px;
          color: #3d3d3d;
          margin-bottom: 25px;
        }
        p {
          font-size: 16px;
          color: #3d3d3d;
          text-indent: 2em;
          line-height: 25px;
        }
      }
    }
  }
  //灰色区域
  .grey-box {
    width: 100%;
    padding-top: 120px;
    padding-bottom: 1px;
    background: #fafafa;
    margin: 0 auto;
    .grey-title {
      font-size: 32px;
      color: #3c3c3c;
      margin-bottom: 73px;
    }
    .grey-content {
      width: 1200px;
      text-align: center;
      margin: auto;
      .grey-content-list {
        display: flex;
        margin-bottom: 130px;
        &:nth-child(2n) {
          flex-direction: row-reverse;
          .grey-list-box {
            padding-left: 0;
          }
        }
        img {
          width: 50%;
        }
        .grey-list-box {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 48px;
          box-sizing: border-box;
          .grey-list-title {
            font-size: 24px;
            color: #3d3d3d;
            text-align: left;
            margin-bottom: 20px;
          }
          .grey-list-content {
            text-align: left;
            /deep/p {
              font-size: 16px;
              line-height: 30px;
              text-indent: 2em;
            }
          }
        }
      }
    }
  }
}
</style>
