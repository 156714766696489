<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_hispital_information_bg.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <mobileTab :tabs="tabs" @tabChange="onTabChange" ref="tabIndex"></mobileTab>
    <!-- 医链云-供应链采购平台 -->
    <div class="mobile-intro-box" v-show="currentIndex == 0">
      <div class="mobile-intro-title">医链云-供应链采购平台</div>
      <p>传世医链云是医院面向供应商的互联网采购平台。</p>
      <p>
        传世医链云通过互联网技术方便医院线上管理供应商的电子化资质材料、方便医院线上下达采购需求、方便医院线上处理供应商送货入库、方便医院线上结算。
      </p>
      <p>
        传世医链云提供供应商移动端工具-恋链，方便供应商实时受理医院采购通知、方便线上提供配送单据与发票、方便线上对账医院结算。
      </p>
      <p>传世医链云率先提供一级库在线管理功能、扫码入库功能、UDI码追溯功能，实现轻量化SPD管理能力。</p>
      <mobileImg :url="ylyBgImg" boxHeight="5.98rem" imgHeight="5.98rem" marginBox=".3rem 0 0 0" ></mobileImg>
    </div>
    <!-- 医链云-区域供应链采购平台 -->
    <div class="mobile-intro-box" v-show="currentIndex == 1">
      <div class="mobile-intro-title">医链云-区域供应链采购平台</div>
      <p>区域医链云是为医院和医药供应商搭建的一个医药运营供应链平台。</p>
      <p>
        满足总院对分院、等级医院对社区医院、集团对下属医院的采购、结算管控；实现医疗机构采购订单网上传递给供应商，配送信息及时传递到医疗机构，提高采购效率。
      </p>
      <p>
        通过对供应商资质证照全面电子化管理，对医疗机构的医药供应链数据智能分析，帮助医院集团和政府实现对医院医药供应链全环节的监管。
      </p>
      <mobileImg :url="ylyqyBgImg" boxHeight="5.98rem" imgHeight="5.98rem" marginBox=".3rem 0 0 0" ></mobileImg>
    </div>
    <!-- SPD-耗材管理系统 -->
    <div class="mobile-intro-box" v-show="currentIndex == 2">
      <div class="mobile-intro-title">SPD-耗材管理系统</div>
      <p>传世耗材SPD覆盖低值普通医用耗材、高值医用耗材的院内UDI码全流程物流管理。</p>
      <p>
        传世耗材SPD帮助医院控制“跑冒滴漏”乱象；帮助医院规范执行《医疗机构医用耗材管理办法》政策；帮助医院降低耗占比，改善财务成本，配合好国家医保体系改革大局。
      </p>
      <p>
        传世耗材SPD可以根据医院物资的定数消耗和扫码计费，对配送计划和动线智能计算，动态调节科室申请与库房补货频次，合理规划配送路径和时间，降低管理成本。并能与院内HIS、HRP、智能耗材柜系统、智能冰箱系统等系统无缝对接，提供基于UDI码的信息追溯和可视化监控。
      </p>
      <mobileImg :url="spdhcBgImg" boxHeight="5.98rem" imgHeight="5.98rem" marginBox=".3rem 0 0 0" ></mobileImg>
    </div>
    <!-- SPD-药品管理系统 -->
    <div class="mobile-intro-box" v-show="currentIndex == 3">
      <div class="mobile-intro-title">SPD-药品管理系统</div>
      <p>
        传世药品SPD以院内药品管理为主，覆盖药库、门诊药房、住院药房，甚至可以深至药柜、摆药机、急救车的细粒度管理。
      </p>
      <p>
        传世药品SPD帮助医院实现“零库存”用后结算；帮助医院完善互联网医院建设的供应保障；帮助医院提升院务管理水平。
      </p>
      <p>
        传世药品SPD通过供应商院内虚拟库存和医院使用后结算的方式，实现医院的零库存。同时通过批次级的精细化库存管理和追溯，保障患者用药安全。传世药品SPD与院内HIS、HRP、自动发药机系统、包药机系统等系统无缝对接，提供全程信息追溯和可视化监控。
      </p>
      <mobileImg :url="spdypBgImg" boxHeight="5.98rem" imgHeight="5.98rem" marginBox=".3rem 0 0 0" ></mobileImg>
    </div>
    <!-- 特色优势 -->
    <mobileTitle titleZh="特色优势" marginTopZh=".64rem" marginBottomZh=".46rem"></mobileTitle>
    <mobileColumnBox v-show="currentIndex == 0" :boxList="boxList1" fontSizeTitle=".36rem"></mobileColumnBox>
    <mobileColumnBox v-show="currentIndex == 1" :boxList="boxList2" fontSizeTitle=".36rem"></mobileColumnBox>
    <mobileColumnBox v-show="currentIndex == 2" :boxList="boxList3" fontSizeTitle=".36rem"></mobileColumnBox>
    <mobileColumnBox v-show="currentIndex == 3" :boxList="boxList4" fontSizeTitle=".36rem"></mobileColumnBox>
  </div>
</template>
<script>
import mobileColumnBox from '@/components/box/mobile_column_box.vue'; //盒子组件
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import mobileTab from '@/components/tab/mobile_tab.vue'; //选项卡组件
import mobileImg from '@/components/img/mobile_img.vue'; //图片组件
export default {
  components: {
    mobileTitle,
    mobileColumnBox,
    mobileTab,
    mobileImg
  },
  data() {
    return {
      currentIndex: 0,
      ylyBgImg:require('../../../assets/mobile_images/mo_hispital_information_yly_bg.png'),
      ylyqyBgImg:require('../../../assets/mobile_images/mo_hispital_information_ylyqy_bg.png'),
      spdhcBgImg:require('../../../assets/mobile_images/mo_hispital_information_spdhc_bg.png'),
      spdypBgImg:require('../../../assets/mobile_images/mo_hispital_information_spdyp_bg.png'),
      tabs: [
        { title: '医链云SAAS-医院供应链平台' },
        { title: '医链云-区域供应链平台' },
        { title: 'SPD-耗材管理系统' },
        { title: 'SPD-药品管理系统' },
      ],
      boxList1: [
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_yly_tsys1.png'),
          title: '医院零库存管理',
          txt:
            '',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_yly_tsys2.png'),
          title: '支持药品、普耗、高植耗材、试剂、中药材、后勤物资的六大类品种',
          txt:
            '医院采购信息平台化，医药物资采集配送一体化，随采随发，结合药链云强大的配送体系及时将医院物资配送医院，可帮助医院实现零库存管理；',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_yly_tsys3.png'),
          title: '线上询价-采购-供应-入库-结算等一体化流程',
          txt:
            '医院采购信息平台化，医药物资采集配送一体化，随采随发，结合药链云强大的配送体系及时将医院物资配送医院，可帮助医院实现零库存管理；',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_yly_tsys4.png'),
          title: '与医院HIS/SPD、供应商ERP无缝对接',
          txt:
            '医院采购信息平台化，医药物资采集配送一体化，随采随发，结合药链云强大的配送体系及时将医院物资配送医院，可帮助医院实现零库存管理；',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_yly_tsys5.png'),
          title: '供应商资质证照管理',
          txt: '支持供应商资质证照管理，为医院提供资质合规性审核、效期监测等服务，使资质管理更合规、更规范、更便捷。',
        },
      ],
      boxList2: [
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_yly_tsys1.png'),
          txt: '<p>支持药品、普耗、高植耗材、试剂、中药材、后勤物资的六大类品种平台采购和配送。</p>',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_ylyqy2.png'),
          txt: '<p>支持总院+分院、紧密型医联体、医共体的采购管控模式。</p>',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_ylyqy3.png'),
          txt: '<p>支持与政府监管平台、医院HIS/SPD、供应商ERP无缝对接</p>',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_yly_tsys5.png'),
          txt: '<p>支持供应商资质证照管理，为医院提供资质合规性审核、效期监测等服务</p>',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_ylyqy5.png'),
          txt: '<p>支持医药供应链全流程运营数据BI分析</p>',
        },
      ],
      boxList3: [
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_spdhc1.png'),
          txt: '<p>支持试剂、普通耗材、高值耗材、后勤物资</p>',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_spdhc2.png'),
          txt: '<p>定数与非定数结合 ，通过定数应用，动态调节科室申请与库房补货频次，减少医护人员工作量</p>',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_spdhc3.png'),
          txt: '<p>配送动线智能计算，通过路径规划，智能计算配送路线与配送班次</p>',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_spdhc4.png'),
          txt: '<p>配货计划动态计算，通过对科室库存的消耗情况自动计算科室补货计算，确保库存不断供、不浪费</p>',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_spdhc5.png'),
          txt: '<p>支持多种设备对接，无缝对接耗材柜、自动发药机、气动物流等</p>',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_spdhc6.png'),
          txt: '<p>提供全面可视化监控，让库房人员直观监控库存及配送管理情况</p>',
        },
      ],
      boxList4: [
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_spdyp1.png'),
          title: '结算点可自由配置',
          txt: '<p>可根据业务情况和药品类型对药品结算点进行一级库入库、二级库入库或二级库消耗等多种结算点配置。</p>',
        },

        {
          url: require('../../../assets/mobile_images/mo_hispital_information_spdyp2.png'),
          title: '全链条库存可视化',
          txt:
            '<p>药房库存药库可视、补货单可自动生成，自动审批（可配置）后由药库主动进行补货作业；</p><p>药库库存供应商可视，实现月度智能补货大计划和药库补货订单自动生成，供应商主动配送。</p>',
        },
        {
          url: require('../../../assets/mobile_images/mo_hispital_information_spdyp3.png'),
          title: '质量管理更加精细化',
          txt:
            '<p>供应商、药品资质证照及授权链管理实现电子化，到期及近效期自动预警；对库存的养护按照GSP标准进行严格的管理，确保在库药品安全。</p>',
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.index) {
      let index = Number(this.$route.query.index);
      this.currentIndex = index;
      this.$refs.tabIndex.currentIndex = index;
    }
  },
  methods: {
    onTabChange(item, index) {
      this.currentIndex = index;
      // this.$refs.tabs.currentIndex = index
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  .mobile-home-header {
    position: relative;
    min-height: 5rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  .mobile-intro-box {
    padding: 0 0.4rem;
    box-sizing: border-box;
    .mobile-intro-title {
      font-size: 0.4rem;
      padding: 0.6rem 0;
      color: #3d3d3d;
      text-align: center;
    }
    p {
      color: #3d3d3d;
      font-size: 0.28rem;
      line-height: 0.5rem;
      text-align: left;
      text-indent: 2em;
    }

    .pic-box {
      //   height: 5.98rem;
      overflow-x: auto;
      overflow-y: hidden;
      img {
        height: 5.98rem;
        margin-top: 0.3rem;
      }
    }
  }
  .mobile-grey-box {
    width: 100%;
    height: 8.54rem;
    padding: 0.4rem;
    background: #f7f7f7;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: hidden;
    img {
      height: 7.74rem;
    }
  }
}
</style>
